import React, { useEffect, useState } from 'react'
import { theme } from '../../utils/theme'

import Button from '../reusable_components/buttons/Button'
import {
	CenteredPageSection,
	CenteredSection,
	DynamicPageSection,
	DynamicPageSectionRow,
	GrayBox,
	LargePageSectionDivider,
	PageSidePaddingWrapper,
	XSmallPageSection,
} from '../reusable_components/layout/PageComponents'
import { P1, P2, P4, TextSpan } from '../reusable_components/Typography'
import Breakpoint from '../reusable_components/responsive/Breakpoint'
import FaqSection, { Faq } from '../reusable_components/other/FaqComponents'
import GetStartedButton from '../static_pages/ejbla_pro_page/GetStartedButton'
import ResponsiveImage from '../reusable_components/responsive/ResponsiveImage'
import BookDemoButton from '../static_pages/ejbla_pro_page/BookDemoButton'
import { Trans } from 'react-i18next-new'
import ReusableSlider from '../reusable_components/other/ReusableSlider'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getCloudinaryImageUrl, getCloudinaryVideoUrl } from '../../utils/imageHelper'

const VideoWrapper = styled.div`
	overflow: hidden;
	position: relative;
	height: 0;
	width: 100%;
	${theme.breakpoints.tablet.upToAndIncluding} {
		padding-bottom: 113.8%;
		background-image: url('${getCloudinaryImageUrl(
			'static_assets/start_page/video_poster_mobile',
			250,
			'ORIGINAL_RATIO',
			',e_blur',
		)}');
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		padding-bottom: 41.7%;
		background-image: url('${getCloudinaryImageUrl(
			'static_assets/start_page/video_poster_desktop',
			400,
			'ORIGINAL_RATIO',
			',e_blur',
		)}');
	}
	background-size: cover;
	> video {
		opacity: ${({ loaded }) => (loaded ? 1 : 0)};
		transition: opacity 0.4s;
		position: absolute;
		width: 100%;
	}
	> div {
		background: #0000003d;
		position: absolute;
		color: white;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		> div {
			margin-top: ${theme.margins.large}; // Force some space between the text and the page header
			${theme.breakpoints.tablet.overAndExcluding} {
				h1 {
					font-size: 50px;
					line-height: 50px;
				}
				p {
					max-width: 550px; //Force the text to be more centered with a max width, so e.g the first line of text isn't super wide and the second line only a couple of words wide. Adjust the max width to suite the current copy. Same should be done for the h1, but that can also be adjusted with font-size
				}
			}
		}
	}
`

const StartPage = props => {
	const { t, i18n, user, routes } = props
	const imageColumns = ['1', '2', '3']
	const numberColumns = ['1', '2', '3', '4']
	const organizerReviewColumns = ['1', '2', '3', '4']
	const [videoLoaded, setVideoLoaded] = useState(false)
	const FirstGoldText = () => (
		<Trans i18nKey={'copy:startPage.goldenText1'}>
			Våra priser är <TextSpan textColor="gold">schyssta</TextSpan>. Det ska vara <TextSpan textColor="gold">gratis</TextSpan> att komma igång och <TextSpan textColor="gold">enkelt</TextSpan> att skala upp
		</Trans>
	) // prettier-ignore
	const MarketplaceText = () => (
		<Trans i18nKey={'copy:startPage.goldenText2'}>
			Med Ejbla är det enkelt att
			<TextSpan textColor="gold">starta, bygga</TextSpan> och
			<TextSpan textColor="gold">skala upp</TextSpan> din verksamhet.
		</Trans>
	)
	const getVideoUrl = type => getCloudinaryVideoUrl(`videos/ejbla_${type}`)
	useEffect(() => {
		const video = document.getElementById('START_PAGE_VIDEO')
		video.addEventListener(
			'loadeddata',
			() => {
				setVideoLoaded(true)
			},
			false,
		)
		video.load() // Event listener loadeddata isn't triggered unless we do load(), even though we have autoPlay
	}, [])
	return (
		<>
			<VideoWrapper loaded={videoLoaded}>
				<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
					<video id="START_PAGE_VIDEO" preload="auto" muted loop autoPlay playsInline width="100%" height="100%">
						<source src={getVideoUrl('mobile')} />
					</video>
				</Breakpoint>
				<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
					<video id="START_PAGE_VIDEO" preload="auto" muted loop autoPlay playsInline width="100%" height="100%">
						<source src={getVideoUrl('desktop')} />
					</video>
				</Breakpoint>
				<div>
					<PageSidePaddingWrapper>
						<CenteredSection>
							<h1>{t('copy:startPage.pageTitle')}</h1>
							<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
								<P2>{t('copy:startPage.pageSubTitle')}</P2>
								{user.isHost || <br />}
							</Breakpoint>
							<GetStartedButton color="OUTLINED_BLACK" primary>
								{t('common:buttons.getStarted')}
							</GetStartedButton>
						</CenteredSection>
					</PageSidePaddingWrapper>
				</div>
			</VideoWrapper>
			<PageSidePaddingWrapper>
				<DynamicPageSectionRow
					margins={{
						all: theme.margins.smallInt,
						innerHorizontalDesktop: theme.margins.largeInt,
						top: theme.margins.largeInt,
					}}
				>
					{imageColumns.map(column => (
						<ImageColumn column={column} key={column} i18n={i18n} t={t} />
					))}
				</DynamicPageSectionRow>
				<CenteredPageSection>
					<BookDemoButton t={t} />
				</CenteredPageSection>
				<LargePageSectionDivider />
				<CenteredPageSection>
					<P1>{t('copy:startPage.secondH2SubTitle')}</P1>
				</CenteredPageSection>
			</PageSidePaddingWrapper>
			<ReusableSlider>
				{organizerReviewColumns.map((column, key) => (
					<OrganizerReviewCard key={key} column={column} t={t} />
				))}
			</ReusableSlider>
			<PageSidePaddingWrapper>
				<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
					<LargePageSectionDivider />
				</Breakpoint>
				<DynamicPageSectionRow
					margins={{
						all: theme.margins.smallInt,
						innerHorizontalDesktop: theme.margins.largeInt,
						bottom: theme.margins.largeInt,
					}}
					reversedOnMobile
				>
					<div>
						<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
							<P2>
								<FirstGoldText />
							</P2>
						</Breakpoint>
						<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
							<P1>
								<FirstGoldText />
							</P1>
						</Breakpoint>
						<DynamicPageSection margins={{ top: theme.margins.largeInt }}>
							<CenteredSection onlyCenterOnMobile>
								<Link to={routes.static.pricing.path}>
									<Button color="OUTLINED_BLACK">{t('common:buttons.seePrices')}</Button>
								</Link>
							</CenteredSection>
						</DynamicPageSection>
					</div>
					<div>
						<ResponsiveImage imagePath={`d2wf0lnza3wudxsxkuap`} type="BIG_RECTANGLE" alt="Tuftning" />
					</div>
				</DynamicPageSectionRow>
			</PageSidePaddingWrapper>
			<GrayBox style={{ padding: '40px 0' }}>
				<PageSidePaddingWrapper>
					<CenteredSection>
						<P1>{t('copy:startPage.thirdH2SubTitle')}</P1>
						<br />
					</CenteredSection>
					<DynamicPageSectionRow
						margins={{ all: theme.margins.smallInt, innerHorizontalDesktop: theme.margins.largeInt }}
					>
						{numberColumns.map(column => (
							<NumberColumn column={column} key={column} t={t} />
						))}
					</DynamicPageSectionRow>
					<CenteredSection>
						<br />
						<a href="https://app.vaam.io/share/SppVIBb" target="_blank">
							<Button>{t('common:buttons.watchPreview')}</Button>
						</a>
					</CenteredSection>
				</PageSidePaddingWrapper>
			</GrayBox>
			<PageSidePaddingWrapper>
				<DynamicPageSectionRow
					margins={{ all: theme.margins.smallInt, innerHorizontalDesktop: theme.margins.largeInt }}
					reversedOnMobile
				>
					<div>
						<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
							<P2>
								<MarketplaceText />
							</P2>
						</Breakpoint>
						<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
							<P1>
								<MarketplaceText />
							</P1>
						</Breakpoint>
						<DynamicPageSection margins={{ top: theme.margins.largeInt }}>
							<CenteredSection onlyCenterOnMobile>
								<BookDemoButton t={t} color="OUTLINED_BLACK" />
							</CenteredSection>
						</DynamicPageSection>
					</div>
					<div>
						<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
							<ResponsiveImage
								imagePath={`sru9y15nqzjzjxlpffje`}
								type="BIG_VERTICAL_RECTANGLE"
								alt="Ejbla marketplace"
							/>
						</Breakpoint>
						<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
							<ResponsiveImage imagePath={`sru9y15nqzjzjxlpffje`} type="BIG_RECTANGLE" alt="Ejbla marketplace" />
						</Breakpoint>
					</div>
				</DynamicPageSectionRow>
			</PageSidePaddingWrapper>
			<FaqSection>
				<div>
					<Faq
						question={t('copy:startPage.faqQuestion1')}
						answer={t('copy:startPage.faqAnswer1', { url: routes.static.pricing.path })}
					/>
					<Faq question={t('copy:startPage.faqQuestion2')} answer={t('copy:startPage.faqAnswer2')} />
				</div>
				<div>
					<Faq question={t('copy:startPage.faqQuestion3')} answer={t('copy:startPage.faqAnswer3')} />
					<Faq question={t('copy:startPage.faqQuestion4')} answer={t('copy:startPage.faqAnswer4')} />
				</div>
			</FaqSection>
		</>
	)
}

const ImageColumn = ({ column, i18n, t }) => {
	const imagePath = `column_image_${column}`
	return (
		<div>
			<ResponsiveImage
				imagePath={`static_assets/start_page/${i18n.language === 'sv' ? imagePath : `${imagePath}_eng`}`}
				type="VERTICAL_RECTANGLE"
			/>
			<br />
			<P2>{t('copy:startPage.imageText' + column)}</P2>
		</div>
	)
}
const NumberColumn = ({ column, t }) => {
	return (
		<div style={{ display: 'flex' }}>
			<P2>{column}</P2>
			<div style={{ marginLeft: theme.margins.small }}>
				<P2>{t(`copy:startPage.numberColumn${column}Title`)}</P2>
				<br />
				<p>{t(`copy:startPage.numberColumn${column}Text`)}</p>
			</div>
		</div>
	)
}
const OrganizerReviewCard = ({ column, t }) => {
	const imagePath = `organizer_review_image_${column}`
	return (
		<div>
			<ResponsiveImage
				imagePath={imagePath}
				alt={imagePath}
				type="BIG_VERTICAL_RECTANGLE"
				// alt={`${fullName} - ${expertType}`}
				sourceWidth={theme.breakpoints.phone.maxInt}
			/>
			<XSmallPageSection>
				<P2>{t(`copy:startPage.organizerReview${column}Name`)}</P2>
			</XSmallPageSection>
			<XSmallPageSection>
				<P4 textColor="lightTextColor">{t(`copy:startPage.organizerReview${column}Type`)}</P4>
			</XSmallPageSection>
			<P4>{t(`copy:startPage.organizerReview${column}Text`)}</P4>
		</div>
	)
}

export default StartPage
